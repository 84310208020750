<template>
<div className="animated">
    <b-card>
      <b-card-header>
        Distributor
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">

          <div slot="Id" slot-scope="props">
            {{props.row.Id}}
          </div>

          <div slot="BiddingStatus" slot-scope="props">
            <!-- <b-progress :max="25" show-value class="mb-3">
              <b-progress-bar :variant="props.row.ChangeStatus=='Confirmed' ? 'success' : 'danger'" :value="props.row.BiddingStatus"></b-progress-bar>
              <b-progress-bar v-if="props.row.ChangeStatus == 'Not Confirmed'" :variant="'success'" :value="props.row.Threshold - props.row.BiddingStatus"></b-progress-bar>
            </b-progress> -->
            <!-- <b-progress :value="props.row.BiddingStatus" :max="props.row.Threshold" show-value class="mb-3" ></b-progress> -->
            {{props.row.BiddingStatus}}
          </div>


          <div slot="ContentEnable" slot-scope="props">
            <c-switch class="mx-1" color="primary" defaultChecked variant="3d" label v-bind="labelIcon"/>
          </div>

          <div slot="Revenue" slot-scope="props">
            {{props.row.Revenue}}$
          </div>

          <div slot="ChangeStatus" slot-scope="props" :class="props.row.ChangeStatus == 'Confirmed' ? 'text-success' : 'text-red'">
            {{props.row.ChangeStatus}}
          </div>

          <div slot="SeatLayout" slot-scope="props">
            <i class="icon-eye" @click="viewSeatLayout()"></i>
          </div>
          <!-- <div slot="OrganizationID" slot-scope="props">
            <i class="fa fa-edit" @click="editOrganization()"></i>
          </div>           -->
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>
import Vue from 'vue'
import { Switch as cSwitch } from '@coreui/vue'
import {ClientTable, Event} from 'vue-tables-2'
import CineAsiaDistributorService from '@/services/CineAsiaDistributorService'
// import OrganizationService from '@/services/OrganizationService'

Vue.use(ClientTable)
export default {
    name: 'Distributor',
    components: {
      ClientTable,
      Event,
      cSwitch
    }, 
    data() {
      return {
        columns: ['Id','Distributor','Title', 'Cinema', 'Location', 'Date', 'Time', 'Capacity', 'Threshold', 'BiddingStatus', 'DateToGo', 'ChangeStatus', 'ContentEnable', 'Revenue', 'KeyValidityDate', 'KeyExpiryDate', 'SeatLayout'],
        data: [],
        options: {
          headings: {
            Id: 'No',
            Distributor: 'Studio',
            Title: 'Movie Title',
            Cinema: 'Cinema',
            Location: 'Location',
            Date: 'Date',
            Time: 'Time',
            Capacity: 'Capacity',
            DateToGo: 'Days to go',
            Threshold: 'Threshold',
            BiddingStatus: 'No. of Tickets booked',
            ChangeStatus: 'Screening Status',
            ContentEnable: 'Content Enable/Disable',
            KeyValidityDate: 'Featured on Gogo since',
            KeyExpiryDate: 'Available till',
            SeatLayout: 'View Seat Layout',
            // KeyValidityDate: 'Key Validity Date upto',
            // KeyPlayCount: 'Key Play Count Remaining',
            Revenue: 'Revenue'
          },
          sortable: ['Cinema', 'Location', 'Date','Time', 'Distributor', 'BiddingStatus'],
          filterable: ['Cinema', 'Location', 'Date', 'Time', 'Distributor'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
            chunk: 5,
            edge: false,
            nav: 'scroll'
          }
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default',
        labelIcon: {
          dataOn: '\u2713',
          dataOff: '\u2715'
        },
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        let res = await CineAsiaDistributorService.getDistList();
        console.log('res :', res);
        this.data = res.data;
        this.data.map((d, index) => {
          d.Id = index+1;
          d.Date = d.DateTime.split(' ')[0];
          d.Time = d.DateTime.split(' ')[1];
          var loc = d.Location.split(',')
          d.Location = loc.length > 2 ? loc[0] + loc[1] : loc
        })
      },
      viewSeatLayout() {
        this.$router.push('/view-seat-layout')
      }
    }
}
</script>


<style>
.text-red {
  color: #fa4502;
}

.icon-eye {
  font-size: 18px;
  cursor: pointer;
}
thead {
  background-color: #2B2D42;
  white-space: nowrap;
  color: #fff;
}

.form-control {
  border-radius: 10px;
}
thead th {
  position: relative;
  font-weight: normal !important;
}
.fa-sort, .fa-sort-asc, .fa-sort-desc {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ddd;
  color: #ddd;
  font-size: 8px;
  padding: 2px;
}
</style>